require('./bootstrap');

$(document).ready(function(){

    var currentQuestion = 0;
    var totalQuestion = 7;

    // Scroll Ancore
    $(document).on('click', '.button-anchor', function (event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });

    // Scroll Top
    $(document).on('click', '.scroll-up', function (event) {
        event.preventDefault();
        $('html, body').animate({scrollTop:0},200);
    });

    // Cookies
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#000000",
                "text": "#ffffff"
            },
            "button": {
                "background": "transparent",
                "text": "#ffffff",
                "border": "#ffffff"
            }
        },
        "position": "bottom-right",
        "content": {
            "link": "Cookie Policy.",
            "message": "<h3>Informativa</h3>Questo sito web utilizza i cookie per assicurarti la migliore esperienza di navigazione possibile. Navigandolo ne accetti l'utilizzo: per avere maggiori informazioni puoi consultare la pagina dedicata alla nostra",
            "dismiss": "OK",
            "href": "/cookie-policy"
        }
    })

    // Tracciamenti
    $("*[data-track-event!='']").click(function(){
        var jsonDataElem = $(this).attr('data-track-event');
        if(jsonDataElem){
            var jsonData = JSON.parse(jsonDataElem);
            gtag('event', jsonData.action, {
                'event_category': jsonData.category,
                'event_label': jsonData.label
            });
        }
    });

    // Owl Carousel - Mobile
    $('#slider-1-mobile.owl-carousel').owlCarousel({
        dots: true,
        nav: false,
        margin: 0,
        items : 1,
        loop: true,
        autoHeight: true,
        responsive: {
            576: {
                nav: true,
            }
        }
    });

    $('#slider-2-mobile.owl-carousel').owlCarousel({
        dots: true,
        nav: false,
        margin: 0,
        items : 1,
        loop: true,
        autoHeight: true,
        responsive: {
            576: {
                nav: true,
            }
        }
    });

    $('#slider-3-mobile.owl-carousel').owlCarousel({
        dots: true,
        nav: false,
        margin: 0,
        items : 1,
        loop: true,
        autoHeight: true,
        responsive: {
            576: {
                nav: true,
            }
        }
    });

    // Owl Carousel - Desktop
    $('#slider-4-desktop.owl-carousel').owlCarousel({
        dots: true,
        nav: false,
        margin: 0,
        items : 1,
        loop: true,
        autoHeight: true,
        responsive: {
            576: {
                nav: true,
            }
        }
    });

    // Owl Carousel - Chi siamo
    $('#slider-chi-siamo.owl-carousel').owlCarousel({
        dots: true,
        nav: false,
        margin: 0,
        items : 1,
        loop: true,
        responsive: {
            576: {
                nav: true,
            }
        }
    });

    // Preventivo before modal fired
    $('#modalPreventivo').on('show.bs.modal', function (e) {
        $(this).find('.errore').toggleClass('errore');
        $(this).find('input[type="text"]').each(function() {
            $(this).val('');
        });
        $(this).find('input[type="numeric"]').each(function() {
            $(this).val('0');
        });
    });

    // Preventivo
    $('#modalPreventivo').on('shown.bs.modal', function (e) {

        // Reset classe errore
        $(this).find('input[type="text"]').keyup(function() {
            if ($(this).val() != '') {
                $(this).removeClass('errore');
            }
        });

        // Evento submit
        $('#formPreventivo').submit(function(e) {
            e.preventDefault();

            $(this).find('.errore').toggleClass('errore');

            let control = true;

            // Attivo
            let attivoImmobiliare = $(this).find('input[name="attivo-immobiliare"]');
            let attivoMobiliare = $(this).find('input[name="attivo-mobiliare"]');

            // Passivo
            let passivoDebitiDipendenti = $(this).find('input[name="passivo-deb-dipendenti"]');
            let passivoDebitiIstitutiProv = $(this).find('input[name="passivo-deb-istituti-prov"]');
            let passivoDebitiImposte = $(this).find('input[name="passivo-deb-imposte"]');
            let passivoDebIva = $(this).find('input[name="passivo-deb-iva"]');
            let passivoDebFinanziari = $(this).find('input[name="passivo-deb-finanziari"]');
            let passivoDebFornitori = $(this).find('input[name="passivo-deb-fornitori"]');
            let passivoDebAltri = $(this).find('input[name="passivo-deb-altri"]');

            // Fatturato
            let totRicavi = $(this).find('input[name="tot-ricavi"]');

            if ( attivoImmobiliare.val() == '' || isNaN(attivoImmobiliare.val())) {
                attivoImmobiliare.addClass('errore');
                control = false;
            }

            if ( attivoMobiliare.val() == '' || isNaN(attivoMobiliare.val())) {
                attivoMobiliare.addClass('errore');
                control = false;
            }

            if ( passivoDebitiDipendenti.val() == '' || isNaN(passivoDebitiDipendenti.val())) {
                passivoDebitiDipendenti.addClass('errore');
                control = false;
            }

            if ( passivoDebitiIstitutiProv.val() == '' || isNaN(passivoDebitiIstitutiProv.val())) {
                passivoDebitiIstitutiProv.addClass('errore');
                control = false;
            }

            if ( passivoDebitiImposte.val() == '' || isNaN(passivoDebitiImposte.val())) {
                passivoDebitiImposte.addClass('errore');
                control = false;
            }

            if ( passivoDebIva.val() == '' || isNaN(passivoDebIva.val())) {
                passivoDebIva.addClass('errore');
                control = false;
            }

            if ( passivoDebFinanziari.val() == '' || isNaN(passivoDebFinanziari.val())) {
                passivoDebFinanziari.addClass('errore');
                control = false;
            }

            if ( passivoDebFornitori.val() == '' || isNaN(passivoDebFornitori.val())) {
                passivoDebFornitori.addClass('errore');
                control = false;
            }

            if ( passivoDebAltri.val() == '' || isNaN(passivoDebAltri.val())) {
                passivoDebAltri.addClass('errore');
                control = false;
            }

            if ( totRicavi.val() == '' || isNaN(totRicavi.val())) {
                totRicavi.addClass('errore');
                control = false;
            }

            if (control == false) {

                //

                return false;

            } else {

                // Compenso fisso
                let totalAttivo = Number(attivoImmobiliare.val()) + Number(attivoMobiliare.val());

                let resultCompenso = '';
                if (totalAttivo <= 100000) {
                    resultCompenso = '4.000';
                } else if (totalAttivo > 100000 && totalAttivo <= 300000) {
                    resultCompenso = '14.000';
                } else if (totalAttivo > 300000 && totalAttivo <= 600000) {
                    resultCompenso = '26.000';
                } else if (totalAttivo > 600000 && totalAttivo <= 1000000) {
                    resultCompenso = '36.000';
                } else if (totalAttivo > 1000000 && totalAttivo <= 2000000) {
                    resultCompenso = '42.000';
                } else {
                    resultCompenso = 'NON QUANTIFICABILE DA CONCORDARE';
                }

                $(this).find('input[name="result-compenso"]').val(resultCompenso)

                // Compenso variabile
                let totalPassivo = (
                    Number(passivoDebitiDipendenti.val())
                    +
                    Number(passivoDebitiIstitutiProv.val())
                    +
                    Number(passivoDebitiImposte.val())
                    +
                    Number(passivoDebIva.val())
                    +
                    Number(passivoDebFinanziari.val())
                    +
                    Number(passivoDebFornitori.val())
                    +
                    Number(passivoDebAltri.val())
                );

                let totalVariabile = calcPercent(totalPassivo - totalAttivo, 4);

                $(this).find('input[name="result-variabile"]').val(Math.abs(totalVariabile.toFixed(2)));

                window.gtag('event', 'click', {
                    'event_category': 'form',
                    'event_label': 'completamento calcola preventivo',
                });

            }
        });
    })

    // Form Slide 1 Calcolo
    $('#questionCalc1').submit(function(e) {

        e.preventDefault();

        $(this).find('.errore').toggleClass('errore');

        let control = true;

        // Attivo
        let attivo = $(this).find('input[name="totale-attivo"]');

        // Passivo
        let passivo = $(this).find('input[name="totale-debiti"]');

        if ( attivo.val() == '' || isNaN(attivo.val())) {
            attivo.addClass('errore');
            control = false;
        }

        if ( passivo.val() == '' || isNaN(passivo.val())) {
            passivo.addClass('errore');
            control = false;
        }

        if (control == false) {

            return false;

        } else {

            let total = Number(attivo.val()) - Number(passivo.val());

            $(this).find('input[name="result-calc"]').val(total.toFixed(0) + ' €');
        }
    });

    // Form Slide 2 Calcolo
    $('#questionCalc2').submit(function(e) {

        e.preventDefault();

        $(this).find('.errore').toggleClass('errore');

        let control = true;

        // Attivo
        let attivo = $(this).find('input[name="totale-attivo"]');

        // Passivo
        let passivo = $(this).find('input[name="totale-debiti"]');

        if ( attivo.val() == '' || isNaN(attivo.val())) {
            attivo.addClass('errore');
            control = false;
        }

        if ( passivo.val() == '' || isNaN(passivo.val())) {
            passivo.addClass('errore');
            control = false;
        }

        if (control == false) {

            return false;

        } else {

            let total = ((Number(attivo.val()) - Number(passivo.val())) / Number(passivo.val())) * 100;

            $(this).find('input[name="result-calc"]').val(total.toFixed(0) + ' %');
        }
    });

    // Form Slide 3 Calcolo
    $('#questionCalc3').submit(function(e) {

        e.preventDefault();

        $(this).find('.errore').toggleClass('errore');

        let control = true;

        // Attivo
        let attivoImmobiliare = $(this).find('input[name="attivo-immobiliare"]');
        let attivoMobiliare = $(this).find('input[name="attivo-mobiliare"]');

        // Passivo
        let passivoDebitiIstitutiProv = $(this).find('input[name="passivo-deb-istituti-prov"]');
        let passivoDebitiImposte = $(this).find('input[name="passivo-deb-imposte"]');
        let passivoDebIva = $(this).find('input[name="passivo-deb-iva"]');

        if ( attivoImmobiliare.val() == '' || isNaN(attivoImmobiliare.val())) {
            attivoImmobiliare.addClass('errore');
            control = false;
        }

        if ( attivoMobiliare.val() == '' || isNaN(attivoMobiliare.val())) {
            attivoMobiliare.addClass('errore');
            control = false;
        }

        if ( passivoDebitiIstitutiProv.val() == '' || isNaN(passivoDebitiIstitutiProv.val())) {
            passivoDebitiIstitutiProv.addClass('errore');
            control = false;
        }

        if ( passivoDebitiImposte.val() == '' || isNaN(passivoDebitiImposte.val())) {
            passivoDebitiImposte.addClass('errore');
            control = false;
        }

        if ( passivoDebIva.val() == '' || isNaN(passivoDebIva.val())) {
            passivoDebIva.addClass('errore');
            control = false;
        }

        if (control == false) {

            return false;

        } else {

            let totalAttivo = Number(attivoImmobiliare.val()) + Number(attivoMobiliare.val());

            let totalPassivo = (
                Number(passivoDebitiIstitutiProv.val())
                +
                Number(passivoDebitiImposte.val())
                +
                Number(passivoDebIva.val())
            );

            let total = (totalPassivo / totalAttivo) * 100;

            $(this).find('input[name="result-calc"]').val(total.toFixed(0) + ' %');
        }
    });

    // Form Slide 4 Calcolo
    $('#questionCalc4').submit(function(e) {

        e.preventDefault();

        $(this).find('.errore').toggleClass('errore');

        let control = true;

            // Passivo
            let passivoDebIva = $(this).find('input[name="passivo-deb-iva"]');

            // Fatturato
            let totRicavi = $(this).find('input[name="tot-ricavi"]');

            if ( passivoDebIva.val() == '' || isNaN(passivoDebIva.val())) {
                passivoDebIva.addClass('errore');
                control = false;
            }

            if ( totRicavi.val() == '' || isNaN(totRicavi.val())) {
                totRicavi.addClass('errore');
                control = false;
            }

        if (control == false) {

            return false;

        } else {

            let total = (Number(passivoDebIva.val()) / Number(totRicavi.val())) * 100;

            $(this).find('input[name="result-calc"]').val(total.toFixed(0) + ' %');
        }
    });

    // Icona ScrollUp
    $(document).scroll(function () {
        var y = $(this).scrollTop();
        if (y > 100) {
            $('.scroll-up').fadeIn();
        } else {
            $('.scroll-up').fadeOut();
        }
    });

    // Reset error class input form
    $('#download-form input, #contact-form input, #questionCalc1 input, #questionCalc2 input, #questionCalc3 input, #questionCalc4 input').keyup(function() {
        if ($(this).val() != '') {
            $(this).removeClass('errore');
        }
    });

    // Reset error class checkbox
    $('#download-form input[type="checkbox"], #contact-form input[type="checkbox"]').change(function() {
        if ($(this).parent().hasClass('errore-checkbox')) {
            $(this).parent().removeClass('errore-checkbox');
        }
    });

    //form-download
    $('#download-form').submit(function(e) {

        e.preventDefault();

        let feedBox = $(this).find('.form-feed');
        let modal = $('#message-modal');

        feedBox.hide();
        feedBox.html('');
        feedBox.removeClass('valid');
        feedBox.removeClass('not-valid');

        $(this).find('.errore').toggleClass('errore');

        let control = true;
        let nome = $(this).find('input[name="nome"]');
        let cognome = $(this).find('input[name="cognome"]');
        let email = $(this).find('input[name="email"]');
        let telefono = $(this).find('input[name="telefono"]');
        let checkbox = $(this).find('input[type="checkbox"]');

        if (nome.val() == '' || nome.val().length < 2) {
            nome.addClass('errore');
            control = false;
        }

        if (cognome.val() == '' || cognome.val().length < 2) {
            cognome.addClass('errore');
            control = false;
        }

        if (email.val() == '' || !(validateMail(email.val()))) {
            email.addClass('errore');
            control = false;
        }

        if (telefono.val() == '' || telefono.val().length < 6) {
            telefono.addClass('errore');
            control = false;
        }

        if (!(checkbox.is(':checked'))) {
            checkbox.parent().addClass('errore-checkbox');
            control = false;
        }

        if (control == false) {

            feedBox.addClass('not-valid');
            feedBox.html('In uno o più campi si sono verificati degli errori. Per favore, compila tutti i campi obbligatori e clicca nuovamente sul pulsante.');
            feedBox.slideDown();

            return false;

        } else {

            $(this).find(':submit').attr('disabled', true);

            $.ajax({

                type: 'POST',
                url: 'downloadmail',
                headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
                data: $(this).serialize(),
                dataType: "json",
                success: function(data) {

                    if (data.success) {

                        window.gtag('event', 'click', {
                            'event_category': 'form',
                            'event_label': 'completamento form scarica guida ',
                        });

                        fbq('track', 'Lead');

                        modal.find('.modal-title').html('Grazie!');

                        nome.val('');
                        cognome.val('');
                        email.val('');
                        telefono.val('');
                        checkbox.prop('checked', false);

                    } else {

                        modal.find('.modal-title').html('Ops...');

                    }

                    modal.find('.modal-body').html(data.messages);
                    modal.modal('toggle');

                    $('#download-form').find(':submit').attr('disabled', false);

                }

            });
        }
    });

    // contact-form
    $('#contact-form').submit(function(e) {

        e.preventDefault();

        let feedBox = $(this).find('.form-feed');
        let modal = $('#message-modal');

        feedBox.hide();
        feedBox.html('');
        feedBox.removeClass('valid');
        feedBox.removeClass('not-valid');

        $(this).find('.errore').toggleClass('errore');

        let control = true;
        let nomeCognome = $(this).find('input[name="nomecognome"]');
        let email = $(this).find('input[name="email"]');
        let telefono = $(this).find('input[name="cell"]');
        let rsociale = $(this).find('input[name="rsociale"]');
        let provincia = $(this).find('input[name="provincia"]');
        let messaggio = $(this).find('textarea[name="messaggio"]');
        let checkbox = $(this).find('input[type="checkbox"]');

        if (nomeCognome.val() == '' || nomeCognome.val().length < 2) {
            nomeCognome.addClass('errore');
            control = false;
        }

        if (email.val() == '' || !(validateMail(email.val()))) {
            email.addClass('errore');
            control = false;
        }

        if (telefono.val() == '' || telefono.val().length < 6) {
            telefono.addClass('errore');
            control = false;
        }

        if (rsociale.val() == '' || rsociale.val().length < 2) {
            rsociale.addClass('errore');
            control = false;
        }

        if (provincia.val() == '' || provincia.val().length != 2) {
            provincia.addClass('errore');
            control = false;
        }

        if (!(checkbox.is(':checked'))) {
            checkbox.parent().addClass('errore-checkbox');
            control = false;
        }

        if (control == false) {

            feedBox.addClass('not-valid');
            feedBox.html('In uno o più campi si sono verificati degli errori. Per favore, compila tutti i campi obbligatori e clicca nuovamente sul pulsante.');
            feedBox.slideDown();

            return false;

        } else {

            $(this).find(':submit').attr('disabled', true);

            $.ajax({

                type: 'POST',
                url: 'contactmail',
                headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
                data: $(this).serialize(),
                dataType: "json",
                success: function(data) {

                    if (data.success) {

                        window.gtag('event', 'click', {
                            'event_category': 'form',
                            'event_label': 'completamento form contatti',
                        });

                        fbq('track', 'Lead');

                    
                        modal.find('.modal-title').html('Verrai ricontattato a breve!');

                        nomeCognome.val('');
                        provincia.val('');
                        email.val('');
                        telefono.val('');
                        rsociale.val('');
                        messaggio.val('');
                        checkbox.prop('checked', false);

                    } else {

                        modal.find('.modal-title').html('Ops...');

                    }

                    modal.find('.modal-body').html(data.messages);
                    modal.modal('toggle');

                    $('#contact-form').find(':submit').attr('disabled', false);

                }

            });
        }
    });

    $(document).on('click', 'a[href="#condizioni-test"]', function (e) {
        e.preventDefault();

        let modal = $('#message-modal');
        modal.find('.modal-title').html('Termini e condizioni del Test');
        modal.find('.modal-body').html('Il test che segue richiede l’inserimento di dati contabili estratti dal bilancio,  dalla contabilità aggiornata, ovvero dalla dichiarazione dei redditi della Tua Impresa. Ricevuti i dati, il software provvede ad elaborare i dati inseriti e restituisce una valutazione dello stato dell’impresa, sulla base dei c.d. “Indici di Allerta” previsti dall’art 13 comma 2 del  Codice della Crisi vigente.<br><br>\
        Resta inteso che lo scopo del presente  Test è puramente informativo ed è limitato a rendere disponibile una prima e sommaria valutazione dello “stato di salute” della Tua impresa.<br><br>\
        Il risultato del Test dipende dall’accuratezza, correttezza e veridicità dei dati inseriti, nonché dalla loro  interpretazione e conformità  alla normativa applicabile.  Esso, dunque, potrebbe non essere sufficientemente preciso o comunque rendere un giudizio non immediatamente affidabile e dunque da approfondire.<br><br>\
        In ogni caso il Test non può sostituire l’accurata  e complessa analisi dei vari aspetti aziendali, legali, fiscali ed economici che solo consulenti specializzati in materia possono rendere.<br><br>\
        Ad essi il team di Restart invita a rivolgersi prima di  prendere qualsiasi decisione e/o porre in essere qualsiasi atto formale.');
        modal.modal('toggle');
    });

    $(document).on('change', '#privacy-test', function (e) {
        if ($(this).is(':checked')) {
            $('.question-first button').removeClass('button-disabled');
            $('#formPreventivo button').removeClass('button-disabled');
        } else {
            $('.question-first button').addClass('button-disabled');
            $('#formPreventivo button').addClass('button-disabled');
        }
    });
    // Test
    // Tasto Close
    $(document).on('click', '.test-close', function (e) {
        testClose();
    });

    // Tasto Indietro
    $(document).on('click', '.test-prev', function (e) {

        if (currentQuestion - 1 == 0) {
            testClose();
        } else {
            changeSlide(currentQuestion - 1);

            let collapse = $('.question-content[data-test="' + currentQuestion +'"]').find('.collapse');

            if (collapse.length > 0) {
                collapse.collapse('hide');
            }

            currentQuestion--;
            changeProgressBar();
            changeQuestionNumber();
        }
    });

    // Abilito tasto successivo al change
    $(document).on( 'change', '.question-content input[type="radio"]', function(){
        $(this).parents(".question-content").find('.question-next').removeClass('button-disabled');
    });

    // Tasto Successivo
    $(document).on('click', '.question-next', function (e) {

        if (!$(this).hasClass('button-disabled')) {

            if (currentQuestion + 1 == 1) {
                $('.question-header').removeClass('d-none');
            }

            if (currentQuestion == 0) {
                window.gtag('event', 'click', {
                    'event_category': 'button',
                    'event_label': 'fai il test',
                });
            } else {
                window.gtag('event', 'click', {
                    'event_category': 'button',
                    'event_label': 'step ' + currentQuestion,
                });
            }

            changeSlide(currentQuestion + 1);

            let collapse = $('.question-content[data-test="' + currentQuestion +'"]').find('.collapse');
            if (collapse.length > 0) {
                collapse.collapse('hide');
            }

            currentQuestion++;
            changeProgressBar();
            changeQuestionNumber();
        }
    });

//  ***************************************************************

    function changeSlide(index) {
        let altezzaSlide = $('.question-content[data-test="' + index +'"]').height();
        let altezzaHeader = $('.question-header').height();
        let wrapperHeight = (Number(altezzaSlide) + Number(altezzaHeader)) + 60;

        $('.questions-wrapper').animate({ 'min-height': wrapperHeight }, 200);

        $('.question-content[data-test="' + currentQuestion +'"]').hide();
        $('.question-content[data-test="' + index +'"]').show();
    }


    function changeProgressBar() {
        let progressBar = $('.step-progress .bar');
        let calcBar = 0;
        if (currentQuestion > totalQuestion) {
            calcBar = 100;
        } else {
            calcBar = (100 / totalQuestion) * currentQuestion;
        }
        progressBar.css('width', calcBar + '%');
    }

    function changeQuestionNumber() {
        if (currentQuestion <= totalQuestion) {
            $('.question-number').html(currentQuestion + '/' + totalQuestion);
        } else {
            calcResult();
        }
    }

    function calcResult() {

        let questionForm = $('#question-form');

        let answer1 = $(questionForm).find('input[name="answer-1"]:checked').val();
        let totPos= 0;
        let totNeg = 0;
        $(questionForm.find('input:checked').not("[name='answer-1']")).each(function() {
            if ($(this).val() == 0) {
                totPos++;
            } else {
                totNeg++;
            }
        });

        let result = '';
        if (answer1 == 2 || totNeg == (totPos + totNeg)) {
            result = 'La tua azienda è in crisi.';
        } else {
            if (totPos > totNeg || totPos == (totPos + totNeg)) {
                result = 'La tua azienda non è attualmente in crisi.<p>(si consiglia comunque un check up al fine di evitarla nel futuro)</p>';
            } else {
                result = 'Possibile crisi latente per la tua impresa.';
            }
        }

        $('.question-number').html('IL TUO RISULTATO');
        $('.question-result').html(result);
    }

    function testClose() {
        let allSlide = $('.question-content');
        let allCard = $('.accordion-calc');
        $('.question-result').html('');

        allSlide.each(function() {
            $(this).find('input[type="radio"]').prop('checked', false);
            if ($(this).data('test') != 0) {
                $(this).find('.question-next').addClass('button-disabled');
            }
        });

        allCard.each(function() {
            $(this).find('input[type="text"]').val('');
            $(this).find('input[type="numeric"]').val('');
            $(this).find('.collapse').collapse('hide');
        });

        $('.question-header').addClass('d-none');

        changeSlide(0);

        currentQuestion = 0;

        changeProgressBar();
    }

    function calcPercent(num, per) {
        return Number((num/100)*per);
    }

    function validateMail(email) {
        var emailCheck = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return emailCheck.test(email);
    }
});